











import {
    Component,
    Vue,
    Inject,
} from 'vue-property-decorator';
import HttpClient from '@/api/http-client';
import Logger from '@/utils/logger/logger';
import Panel from '@/views/Panel.vue';
import UserInfo from '@/core/userinfo';
import WebSocket from '@/socket';
import { urlWebSocket } from '@/utils/url';

@Component({
    components: {
        Panel,
    },
})
export default class PanelContainer extends Vue {
    @Inject('logger') logger!: Logger;

    @Inject('httpClient') http!: HttpClient;

    socket!: WebSocket;

    userInfo: UserInfo = UserInfo.fromInit();

    created() {
        const token = sessionStorage.getItem('token') || '';

        UserInfo.fromApi(this.http)
            .then((userinfo: UserInfo) => {
                this.userInfo = userinfo;

                this.$root.$emit('customers', this.userInfo.customers);
                this.$root.$emit('currentDomain', this.userInfo.currentDomain);
            });

        this.socket = WebSocket.fromToken(urlWebSocket, token, this.logger);
    }
}
